var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      ref: "form",
      staticClass: "wrap",
      attrs: {
        model: _vm.serviceForm,
        "label-width": "96px",
        rules: _vm.rules,
        id: "wrap",
      },
    },
    [
      _c("topOperatingButton", {
        attrs: {
          defaultProps: _vm.defaultProps,
          isSubmitBtn: _vm.serviceForm.goodsType != 3,
          isSubmitAddBtn: _vm.serviceForm.goodsType != 3,
          isAuditBillBtn: _vm.serviceForm.goodsType != 3,
          disabled: _vm.disabled,
          showAudit: _vm.showAudit,
          isAddBtn: _vm.showAdd,
          id: "topOperatingButton",
        },
        on: {
          submitForm: function ($event) {
            return _vm.submitForm(true)
          },
          addBill: _vm.addBill,
          auditBill: _vm.auditBill,
          handleAdd: _vm.handleAdd,
          getQuit: _vm.getQuit,
        },
      }),
      _c(
        "div",
        { staticClass: "x-f" },
        [
          _c("categoryTreeCard", {
            attrs: {
              bomAnnotation: "双击进入该半成品配方",
              categoryTreeTitle: "产品配方结构",
              isDoubleClick: _vm.isDoubleClick,
              categoryTreeOptions: _vm.categoryTreeOptions,
              boxHeight: _vm.boxHeight,
              boxInternalHeight: _vm.boxInternalHeight,
            },
            on: {
              treeNodeId: _vm.getTreeNodeId,
              handleNodeDoubleClick: _vm.handleNodeDoubleClick,
            },
          }),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loadingGoods,
                  expression: "loadingGoods",
                },
              ],
              staticClass: "rightBox y-b",
            },
            [
              _c(
                "cardTitleCom",
                {
                  attrs: {
                    cardTitle: "基本信息",
                    billStatus: _vm.serviceForm.bomStatus,
                    id: "search-card",
                  },
                },
                [
                  _c("template", { slot: "cardContent" }, [
                    _c(
                      "div",
                      { staticClass: "x-w marT10" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "配方编码", prop: "bomNo" } },
                          [
                            _c("el-input", {
                              staticClass: "inputWidth",
                              attrs: {
                                disabled: "",
                                placeholder: "配方编码",
                                size: "mini",
                              },
                              model: {
                                value: _vm.serviceForm.bomNo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.serviceForm, "bomNo", $$v)
                                },
                                expression: "serviceForm.bomNo",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "商品编码", prop: "goodsId" } },
                          [
                            _c("SelectRemote", {
                              attrs: {
                                option: _vm.$select({
                                  key: "listBomGoods",
                                  option: {
                                    option: {
                                      disabled:
                                        _vm.disabled ||
                                        _vm.serviceForm.goodsType == 3,
                                      showItem: [
                                        {
                                          goodsId: _vm.serviceForm.goodsId,
                                          goodsName: _vm.serviceForm.goodsNo,
                                        },
                                      ],
                                    },
                                  },
                                }).option,
                              },
                              on: { selectChange: _vm.selectChangeGoods },
                              model: {
                                value: _vm.serviceForm.goodsId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.serviceForm, "goodsId", $$v)
                                },
                                expression: "serviceForm.goodsId",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "商品名称", prop: "goodsName" } },
                          [
                            _c("el-input", {
                              staticClass: "inputWidth",
                              attrs: {
                                size: "mini",
                                disabled: true,
                                placeholder: "商品名称",
                              },
                              model: {
                                value: _vm.serviceForm.goodsName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.serviceForm, "goodsName", $$v)
                                },
                                expression: "serviceForm.goodsName",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "规格型号", prop: "goodsSpec" } },
                          [
                            _c("el-input", {
                              staticClass: "inputWidth",
                              attrs: {
                                size: "mini",
                                disabled: true,
                                placeholder: "规格型号",
                              },
                              model: {
                                value: _vm.serviceForm.goodsSpec,
                                callback: function ($$v) {
                                  _vm.$set(_vm.serviceForm, "goodsSpec", $$v)
                                },
                                expression: "serviceForm.goodsSpec",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "版本号", prop: "bomVer" } },
                          [
                            _c("el-input", {
                              staticClass: "inputWidth",
                              attrs: {
                                size: "mini",
                                disabled: true,
                                placeholder: "版本号",
                              },
                              model: {
                                value: _vm.serviceForm.bomVer,
                                callback: function ($$v) {
                                  _vm.$set(_vm.serviceForm, "bomVer", $$v)
                                },
                                expression: "serviceForm.bomVer",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "单位", prop: "unitId" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "inputWidth",
                                attrs: { size: "mini", disabled: true },
                                model: {
                                  value: _vm.serviceForm.unitId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.serviceForm, "unitId", $$v)
                                  },
                                  expression: "serviceForm.unitId",
                                },
                              },
                              _vm._l(_vm.serviceForm.units, function (item) {
                                return _c("el-option", {
                                  key: item.unitId,
                                  attrs: {
                                    label: item.unitName,
                                    value: item.unitId,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "成品率(%)", prop: "yieldRate" } },
                          [
                            _c("el-input", {
                              staticClass: "inputWidth",
                              attrs: {
                                type: "number",
                                size: "mini",
                                disabled:
                                  _vm.disabled ||
                                  _vm.serviceForm.goodsType == 3,
                                placeholder: "成品率",
                              },
                              model: {
                                value: _vm.serviceForm.yieldRate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.serviceForm, "yieldRate", $$v)
                                },
                                expression: "serviceForm.yieldRate",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label:
                                "" + (_vm.isRootNode ? "批产量" : "批用量"),
                              prop: "batchProdQty",
                            },
                          },
                          [
                            _c("el-input", {
                              staticClass: "inputWidth",
                              attrs: {
                                type: "number",
                                size: "mini",
                                disabled:
                                  _vm.disabled ||
                                  _vm.serviceForm.goodsType == 3,
                                placeholder:
                                  "" + (_vm.isRootNode ? "批产量" : "批用量"),
                              },
                              nativeOn: {
                                keyup: function ($event) {
                                  return _vm.onkeyupBatchProdQty($event)
                                },
                              },
                              model: {
                                value: _vm.serviceForm.batchProdQty,
                                callback: function ($$v) {
                                  _vm.$set(_vm.serviceForm, "batchProdQty", $$v)
                                },
                                expression: "serviceForm.batchProdQty",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "成本价", prop: "oneCostMoney" } },
                          [
                            _c("el-input", {
                              staticClass: "inputWidth",
                              attrs: {
                                size: "mini",
                                disabled: true,
                                placeholder: "成本价",
                              },
                              model: {
                                value: _vm.serviceForm.oneCostMoney,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.serviceForm,
                                    "oneCostMoney",
                                    _vm._n($$v)
                                  )
                                },
                                expression: "serviceForm.oneCostMoney",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "批量成本",
                              prop: "batchCostMoney",
                            },
                          },
                          [
                            _c("el-input", {
                              staticClass: "inputWidth",
                              attrs: {
                                size: "mini",
                                disabled: true,
                                placeholder: "批量成本",
                              },
                              model: {
                                value: _vm.serviceForm.batchCostMoney,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.serviceForm,
                                    "batchCostMoney",
                                    _vm._n($$v)
                                  )
                                },
                                expression: "serviceForm.batchCostMoney",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "备注", prop: "remark" } },
                          [
                            _c("el-input", {
                              staticClass: "inputRemark",
                              attrs: {
                                size: "mini",
                                disabled:
                                  _vm.disabled ||
                                  _vm.serviceForm.goodsType == 3,
                                type: "textarea",
                                placeholder: "备注长度介于 1 和 80 字符之间",
                                maxlength: "80",
                                autosize: { minRows: 1, maxRows: 5 },
                              },
                              model: {
                                value: _vm.serviceForm.remark,
                                callback: function ($$v) {
                                  _vm.$set(_vm.serviceForm, "remark", $$v)
                                },
                                expression: "serviceForm.remark",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
              _c(
                "cardTitleCom",
                {
                  staticClass: "headGoodsTable",
                  attrs: {
                    cardTitle: _vm.serviceForm.bomId ? null : "基本信息",
                  },
                },
                [
                  _vm.serviceForm.bomId
                    ? _c(
                        "template",
                        { slot: "leftCardTitle" },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticStyle: { "margin-right": "10px" },
                              attrs: { size: "mini" },
                              model: {
                                value: _vm.tabType,
                                callback: function ($$v) {
                                  _vm.tabType = $$v
                                },
                                expression: "tabType",
                              },
                            },
                            [
                              _c("el-radio-button", { attrs: { label: 1 } }, [
                                _vm._v("子件信息"),
                              ]),
                              _c("el-radio-button", { attrs: { label: 2 } }, [
                                _vm._v("配方工序"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("template", { slot: "cardContent" }, [
                    _vm.serviceForm.goodsType != 3
                      ? _c(
                          "div",
                          { staticClass: "goodsMessage" },
                          [
                            _vm.serviceForm.bomId && _vm.tabType == 2
                              ? _c(
                                  "el-button",
                                  {
                                    staticStyle: { "margin-bottom": "5px" },
                                    attrs: {
                                      type: "success",
                                      size: "mini",
                                      disabled:
                                        _vm.serviceForm.bomStatus != "2",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.submitProcess()
                                      },
                                    },
                                  },
                                  [_vm._v("保存工序 ")]
                                )
                              : _vm._e(),
                            _c(
                              "el-table",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.tabType == 1,
                                    expression: "tabType==1",
                                  },
                                ],
                                ref: "multipleTable1",
                                attrs: {
                                  data: _vm.serviceForm.bomDetail,
                                  border: "",
                                  "max-height": _vm.tableHeight,
                                  height: _vm.tableHeight,
                                  "show-summary": "",
                                  "summary-method": _vm.getSummaries,
                                },
                                on: {
                                  "cell-mouse-enter": _vm.cellMouseEnter,
                                  "cell-mouse-leave": _vm.cellMouseLeave,
                                },
                              },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    align: "center",
                                    label: _vm.tableCellLabel,
                                    width: "80",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            scope.row.hoverRow
                                              ? _c("i", {
                                                  staticClass:
                                                    "el-icon-circle-plus operatePush",
                                                  attrs: {
                                                    disabled: _vm.disabled,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.row(
                                                        "push",
                                                        scope.$index
                                                      )
                                                    },
                                                  },
                                                })
                                              : _vm._e(),
                                            scope.row.hoverRow
                                              ? _c("i", {
                                                  staticClass:
                                                    "el-icon-remove operateDel",
                                                  attrs: {
                                                    disabled: _vm.disabled,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.row(
                                                        "del",
                                                        scope.$index
                                                      )
                                                    },
                                                  },
                                                })
                                              : _vm._e(),
                                            !scope.row.hoverRow
                                              ? _c("div", [
                                                  _vm._v(
                                                    _vm._s(scope.$index + 1)
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1654891108
                                  ),
                                }),
                                _c(
                                  "el-table-column",
                                  {
                                    attrs: {
                                      label: "商品编码",
                                      "min-width": "180",
                                      align: "center",
                                      prop: "goodsNo",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    prop:
                                                      "bomDetail." +
                                                      scope.$index +
                                                      ".goodsNo",
                                                    rules:
                                                      _vm.rules[
                                                        "bomDetail.goodsNoMaterial"
                                                      ],
                                                  },
                                                },
                                                [
                                                  _c("SelectRemote", {
                                                    ref:
                                                      "goodsNo" +
                                                      "_" +
                                                      scope.$index +
                                                      "_1",
                                                    attrs: {
                                                      size: "mini",
                                                      disabled: _vm.disabled,
                                                      option: _vm.optionGoods,
                                                      filterable: true,
                                                      placeholder: "选择商品",
                                                    },
                                                    model: {
                                                      value: scope.row.goodsNo,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "goodsNo",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.goodsNo",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3974880458
                                    ),
                                  },
                                  [
                                    _c("template", { slot: "header" }, [
                                      _c(
                                        "i",
                                        { staticStyle: { color: "#ff4949" } },
                                        [_vm._v("* ")]
                                      ),
                                      _c("span", [_vm._v("商品编码")]),
                                    ]),
                                  ],
                                  2
                                ),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "商品名称",
                                    align: "center",
                                    prop: "goodsName",
                                    "show-overflow-tooltip": "",
                                    "min-width": "120",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "规格",
                                    align: "center",
                                    prop: "goodsSpec",
                                    "show-overflow-tooltip": "",
                                    "min-width": "100",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "版本号",
                                    align: "center",
                                    prop: "bomVer",
                                    "show-overflow-tooltip": "",
                                    "min-width": "80",
                                  },
                                }),
                                _c(
                                  "el-table-column",
                                  {
                                    attrs: {
                                      label: "单位",
                                      align: "center",
                                      prop: "unitId",
                                      "min-width": "120",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    prop:
                                                      "bomDetail." +
                                                      scope.$index +
                                                      ".unitId",
                                                    rules:
                                                      _vm.rules[
                                                        "bomDetail.unitIdMaterial"
                                                      ],
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-select",
                                                    {
                                                      attrs: {
                                                        size: "mini",
                                                        disabled: _vm.disabled,
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          return _vm.unitPriceChange(
                                                            $event,
                                                            scope.row
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value: scope.row.unitId,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            "unitId",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row.unitId",
                                                      },
                                                    },
                                                    _vm._l(
                                                      scope.row.units,
                                                      function (item) {
                                                        return _c("el-option", {
                                                          key: item.unitId,
                                                          attrs: {
                                                            label:
                                                              item.unitName,
                                                            value: item.unitId,
                                                          },
                                                        })
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      783247207
                                    ),
                                  },
                                  [
                                    _c("template", { slot: "header" }, [
                                      _c(
                                        "i",
                                        { staticStyle: { color: "#ff4949" } },
                                        [_vm._v("* ")]
                                      ),
                                      _c("span", [_vm._v("单位")]),
                                    ]),
                                  ],
                                  2
                                ),
                                _c(
                                  "el-table-column",
                                  {
                                    attrs: {
                                      label: "单个用量",
                                      align: "center",
                                      "min-width": "120",
                                      prop: "oneUseQty",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    prop:
                                                      "bomDetail." +
                                                      scope.$index +
                                                      ".oneUseQty",
                                                    rules:
                                                      _vm.rules[
                                                        "bomDetail.oneUseQtyMaterial"
                                                      ],
                                                  },
                                                },
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      type: "number",
                                                      maxlength: "15",
                                                      size: "mini",
                                                      disabled: _vm.disabled,
                                                    },
                                                    nativeOn: {
                                                      keyup: function ($event) {
                                                        return _vm.onkeyupOneUseQty(
                                                          scope.row,
                                                          1
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        scope.row.oneUseQty,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "oneUseQty",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.oneUseQty",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      422504960
                                    ),
                                  },
                                  [
                                    _c("template", { slot: "header" }, [
                                      _c(
                                        "i",
                                        { staticStyle: { color: "#ff4949" } },
                                        [_vm._v("* ")]
                                      ),
                                      _c("span", [_vm._v("单个用量")]),
                                    ]),
                                  ],
                                  2
                                ),
                                _c(
                                  "el-table-column",
                                  {
                                    attrs: {
                                      label: "单个用量(克)",
                                      align: "center",
                                      prop: "oneUseQtyGram",
                                      "min-width": "120",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    prop:
                                                      "bomDetail." +
                                                      scope.$index +
                                                      ".oneUseQtyGram",
                                                    rules:
                                                      _vm.rules[
                                                        "bomDetail.oneUseQtyMaterial"
                                                      ],
                                                  },
                                                },
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      type: "number",
                                                      maxlength: "15",
                                                      size: "mini",
                                                      disabled: _vm.disabled,
                                                    },
                                                    nativeOn: {
                                                      keyup: function ($event) {
                                                        return _vm.onkeyupOneUseQty(
                                                          scope.row,
                                                          2
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        scope.row.oneUseQtyGram,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "oneUseQtyGram",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.oneUseQtyGram",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2903805059
                                    ),
                                  },
                                  [
                                    _c("template", { slot: "header" }, [
                                      _c(
                                        "i",
                                        { staticStyle: { color: "#ff4949" } },
                                        [_vm._v("* ")]
                                      ),
                                      _c("span", [_vm._v("单个用量(克)")]),
                                    ]),
                                  ],
                                  2
                                ),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "成本价",
                                    align: "center",
                                    "show-overflow-tooltip": "",
                                    prop: "oneCostPrice",
                                    "min-width": "120",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "成本",
                                    align: "center",
                                    "show-overflow-tooltip": "",
                                    prop: "oneCostMoney",
                                    "min-width": "120",
                                  },
                                }),
                                _c(
                                  "el-table-column",
                                  {
                                    attrs: {
                                      label: "批产量用量",
                                      align: "center",
                                      prop: "batchUseQty",
                                      "min-width": "120",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    prop:
                                                      "bomDetail." +
                                                      scope.$index +
                                                      ".batchUseQty",
                                                    rules:
                                                      _vm.rules[
                                                        "bomDetail.batchUseQty"
                                                      ],
                                                  },
                                                },
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      type: "number",
                                                      maxlength: "15",
                                                      size: "mini",
                                                      disabled: _vm.disabled,
                                                    },
                                                    nativeOn: {
                                                      keyup: function ($event) {
                                                        return _vm.onkeyupBatchUseQty(
                                                          scope.row,
                                                          1
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        scope.row.batchUseQty,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "batchUseQty",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.batchUseQty",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3666325835
                                    ),
                                  },
                                  [
                                    _c("template", { slot: "header" }, [
                                      _c(
                                        "i",
                                        { staticStyle: { color: "#ff4949" } },
                                        [_vm._v("* ")]
                                      ),
                                      _c("span", [_vm._v("批产量用量")]),
                                    ]),
                                  ],
                                  2
                                ),
                                _c(
                                  "el-table-column",
                                  {
                                    attrs: {
                                      label: "批产量用量(克)",
                                      align: "center",
                                      prop: "batchUseQtyGram",
                                      "min-width": "120",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    prop:
                                                      "bomDetail." +
                                                      scope.$index +
                                                      ".batchUseQtyGram",
                                                    rules:
                                                      _vm.rules[
                                                        "bomDetail.batchUseQtyGram"
                                                      ],
                                                  },
                                                },
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      type: "number",
                                                      maxlength: "15",
                                                      size: "mini",
                                                      disabled: _vm.disabled,
                                                    },
                                                    nativeOn: {
                                                      keyup: function ($event) {
                                                        return _vm.onkeyupBatchUseQty(
                                                          scope.row,
                                                          2
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        scope.row
                                                          .batchUseQtyGram,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "batchUseQtyGram",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.batchUseQtyGram",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3993718033
                                    ),
                                  },
                                  [
                                    _c("template", { slot: "header" }, [
                                      _c(
                                        "i",
                                        { staticStyle: { color: "#ff4949" } },
                                        [_vm._v("* ")]
                                      ),
                                      _c("span", [_vm._v("批产量用量(克)")]),
                                    ]),
                                  ],
                                  2
                                ),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "批量成本",
                                    align: "center",
                                    prop: "batchCostMoney",
                                    "min-width": "120",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "损耗率(%)",
                                    align: "center",
                                    prop: "loseRate",
                                    "min-width": "100",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  prop:
                                                    "bomDetail." +
                                                    scope.$index +
                                                    ".loseRate",
                                                  rules:
                                                    _vm.rules[
                                                      "bomDetail.loseRateMaterial"
                                                    ],
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    type: "number",
                                                    size: "mini",
                                                    disabled: _vm.disabled,
                                                    maxlength: "8",
                                                  },
                                                  model: {
                                                    value: scope.row.loseRate,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "loseRate",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.loseRate",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1517034965
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "备注",
                                    align: "center",
                                    prop: "remark",
                                    "min-width": "155",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("el-input", {
                                              attrs: {
                                                size: "mini",
                                                disabled: _vm.disabled,
                                                maxlength: "80",
                                              },
                                              model: {
                                                value: scope.row.remark,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "remark",
                                                    $$v
                                                  )
                                                },
                                                expression: "scope.row.remark",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3029735431
                                  ),
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-table",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.tabType == 2,
                                    expression: "tabType==2",
                                  },
                                ],
                                ref: "multipleTable2",
                                attrs: {
                                  data: _vm.bomProcessSettingList,
                                  border: "",
                                  "max-height": _vm.tableHeight,
                                  height: _vm.tableHeight,
                                },
                              },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    label: "序号",
                                    align: "center",
                                    width: "80",
                                    type: "index",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "商品编码",
                                    align: "center",
                                    prop: "goodsNo",
                                    "min-width": "120",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "商品名称",
                                    align: "center",
                                    prop: "goodsName",
                                    "min-width": "120",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "规格",
                                    align: "center",
                                    prop: "goodsSpec",
                                    "min-width": "100",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "单位",
                                    align: "center",
                                    prop: "unitName",
                                    "min-width": "80",
                                  },
                                }),
                                _vm._l(
                                  _vm.bomProcessSettingList.length > 0 &&
                                    _vm.bomProcessSettingList[0].list
                                    ? _vm.bomProcessSettingList[0].list
                                    : [],
                                  function (item, index) {
                                    return [
                                      _c(
                                        "el-table-column",
                                        {
                                          key:
                                            index +
                                            "," +
                                            item.processInfo.processId +
                                            "," +
                                            _vm.getUid(),
                                          attrs: {
                                            label: item.processInfo.processName,
                                            align: "center",
                                            width: "200",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-table-column",
                                            {
                                              attrs: {
                                                align: "center",
                                                width: "80",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c("el-checkbox", {
                                                          attrs: {
                                                            disabled:
                                                              _vm.serviceForm
                                                                .bomStatus !=
                                                              "2",
                                                          },
                                                          model: {
                                                            value:
                                                              scope.row.list[
                                                                index
                                                              ].isUse,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                scope.row.list[
                                                                  index
                                                                ],
                                                                "isUse",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "scope.row.list[index].isUse",
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "header" },
                                                [_c("div", [_vm._v(" 启用 ")])]
                                              ),
                                            ],
                                            2
                                          ),
                                          _c(
                                            "el-table-column",
                                            {
                                              attrs: {
                                                align: "center",
                                                width: "80",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c("el-checkbox", {
                                                          attrs: {
                                                            disabled:
                                                              _vm.serviceForm
                                                                .bomStatus !=
                                                              "2",
                                                          },
                                                          model: {
                                                            value:
                                                              scope.row.list[
                                                                index
                                                              ].isProduce,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                scope.row.list[
                                                                  index
                                                                ],
                                                                "isProduce",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "scope.row.list[index].isProduce",
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "header" },
                                                [_c("div", [_vm._v(" 生产 ")])]
                                              ),
                                            ],
                                            2
                                          ),
                                          _c(
                                            "el-table-column",
                                            {
                                              attrs: {
                                                align: "center",
                                                width: "80",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c("el-checkbox", {
                                                          attrs: {
                                                            disabled:
                                                              _vm.serviceForm
                                                                .bomStatus !=
                                                              "2",
                                                          },
                                                          model: {
                                                            value:
                                                              scope.row.list[
                                                                index
                                                              ].isHandover,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                scope.row.list[
                                                                  index
                                                                ],
                                                                "isHandover",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "scope.row.list[index].isHandover",
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "header" },
                                                [_c("div", [_vm._v(" 交接 ")])]
                                              ),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  }
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }