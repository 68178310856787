var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "wrap x-f",
      attrs: { id: "wrap" },
    },
    [
      _c("categoryTreeCard", {
        attrs: {
          categoryTreeTitle: "配方商品类别",
          boxHeight: _vm.boxHeight,
          boxInternalHeight: _vm.boxInternalHeight,
          isNoProduct: true,
          categoryTreeOptions: _vm.categoryTreeOptions,
        },
        on: { treeNodeId: _vm.getTreeNodeId, isHalfGoods: _vm.getIsHalfGoods },
      }),
      _c(
        "div",
        { staticClass: "rightBox y-b" },
        [
          _c(
            "cardTitleCom",
            {
              staticClass: "headGoodsSearch",
              attrs: { cardTitle: "查询", id: "search-card" },
            },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "navSearch" },
                  [
                    _c("seniorSearch", {
                      attrs: {
                        getList: _vm.getList,
                        dropDownList: _vm.dropDownList,
                        isBillDate: false,
                        isSearchInput: true,
                        isSearchInputTitle: "输入配方编号、商品编码、名称查询",
                      },
                      on: { isShowHighCom: _vm.getIsShowHigh },
                      model: {
                        value: _vm.queryParams,
                        callback: function ($$v) {
                          _vm.queryParams = $$v
                        },
                        expression: "queryParams",
                      },
                    }),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showHigh,
                            expression: "showHigh",
                          },
                        ],
                        staticClass: "x-f marT10",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "marR15" },
                          [
                            _c("span", { staticClass: "fS14MR10" }, [
                              _vm._v("启用状态"),
                            ]),
                            _c(
                              "el-select",
                              {
                                staticClass: "inputWidth",
                                attrs: {
                                  size: "mini",
                                  placeholder: "全部",
                                  filterable: "",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.queryParams.isStop,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.queryParams, "isStop", $$v)
                                  },
                                  expression: "queryParams.isStop",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "停用", value: true },
                                }),
                                _c("el-option", {
                                  attrs: { label: "启用", value: false },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("span", { staticClass: "fS14MR10" }, [
                              _vm._v("单据状态"),
                            ]),
                            _c(
                              "el-select",
                              {
                                staticClass: "inputWidth",
                                attrs: {
                                  size: "mini",
                                  placeholder: "全部",
                                  filterable: "",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.queryParams.bomStatus,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.queryParams, "bomStatus", $$v)
                                  },
                                  expression: "queryParams.bomStatus",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "未审核", value: "0" },
                                }),
                                _c("el-option", {
                                  attrs: { label: "已审核", value: "2" },
                                }),
                                _c("el-option", {
                                  attrs: { label: "已关闭", value: "4" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            { staticClass: "headGoodsTable", attrs: { cardTitle: "单据信息" } },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "goodsMessage" },
                  [
                    _c(
                      "operatingButton",
                      {
                        attrs: {
                          getList: _vm.getList,
                          isAuditBtn: true,
                          multiple: _vm.multiple,
                          isExportBtn: true,
                          isImportBtn: true,
                        },
                        on: {
                          handleAudit: function ($event) {
                            return _vm.auditBill("审核")
                          },
                          handleRedoAudit: function ($event) {
                            return _vm.auditBill("反审核")
                          },
                          handleAdd: _vm.handleAdd,
                          handleDelete: function ($event) {
                            return _vm.auditBill("删除")
                          },
                          handleExport: _vm.handleExport,
                          handleImport: function ($event) {
                            _vm.openToChannel = true
                          },
                        },
                      },
                      [
                        _c(
                          "template",
                          { slot: "specialDiyBtn" },
                          [
                            _c(
                              "el-dropdown",
                              {
                                staticClass: "marR10",
                                attrs: {
                                  "split-button": "",
                                  type: "warning",
                                  icon: "el-icon-circle-check",
                                  disabled: _vm.multiple,
                                  size: "mini",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.getEnable(0)
                                  },
                                  command: function ($event) {
                                    return _vm.getEnable(1)
                                  },
                                },
                              },
                              [
                                _vm._v(" 启用 "),
                                _c(
                                  "el-dropdown-menu",
                                  {
                                    attrs: { slot: "dropdown" },
                                    slot: "dropdown",
                                  },
                                  [
                                    _c(
                                      "el-dropdown-item",
                                      { attrs: { command: "禁用" } },
                                      [_vm._v("禁用")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-button",
                              {
                                staticStyle: {
                                  color: "#fff",
                                  "background-color": "#12cc9f",
                                },
                                attrs: {
                                  icon: "el-icon-document-copy",
                                  disabled: _vm.multiple,
                                  size: "mini",
                                },
                                on: { click: _vm.getCopyOrder },
                              },
                              [_vm._v("复制 ")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  icon: "el-icon-arrow-down",
                                  size: "mini",
                                },
                                on: { click: _vm.arrowDownUp },
                              },
                              [_vm._v("全部展开/收缩 ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loadingTable,
                                expression: "loadingTable",
                              },
                            ],
                            ref: "multipleTable",
                            attrs: {
                              "tooltip-effect": "dark",
                              data: _vm.tableData,
                              border: "",
                              "max-height": _vm.tableHeight,
                              height: _vm.tableHeight,
                              "tree-props": {
                                children: "childList",
                                hasChildren: "hasChildren",
                              },
                              "row-key": "bomId",
                            },
                            on: {
                              "selection-change": _vm.handleSelectionChange,
                              "row-click": _vm.handleRowClick,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                type: "selection",
                                width: "50",
                                align: "center",
                                selectable: _vm.disableCheckbox,
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "序号",
                                align: "center",
                                width: "80",
                                prop: "parentIndex",
                                type: "",
                              },
                            }),
                            _c("el-table-column", {
                              key: "bomNo",
                              attrs: {
                                "show-overflow-tooltip": "",
                                label: "配方编号",
                                align: "center",
                                prop: "bomNo",
                                "min-width": "200",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-link",
                                        {
                                          attrs: {
                                            type: "primary",
                                            target: "_blank",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleDetail(scope.row)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(scope.row.bomNo) + " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "商品编码",
                                align: "center",
                                prop: "goodsNo",
                                "min-width": "180",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "商品名称",
                                align: "center",
                                prop: "goodsName",
                                "min-width": "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "条码",
                                align: "center",
                                prop: "barcode",
                                "min-width": "180",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "单位",
                                align: "center",
                                prop: "unitName",
                                "min-width": "100",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "商品类别",
                                align: "center",
                                prop: "categoryName",
                                "min-width": "160",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "单个用量",
                                align: "center",
                                prop: "oneUseQty",
                                "min-width": "100",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "单个用量(克)",
                                align: "center",
                                prop: "oneUseQtyGram",
                                "min-width": "100",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "批产量/用量",
                                align: "center",
                                prop: "batchProdQty",
                                "min-width": "110",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "批产量/用量(克)",
                                align: "center",
                                prop: "batchUseQtyGram",
                                "min-width": "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "成本",
                                align: "center",
                                prop: "oneCostMoney",
                                "min-width": "100",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "批量成本",
                                align: "center",
                                prop: "batchCostMoney",
                                "min-width": "100",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "损耗率(%)",
                                align: "center",
                                prop: "loseRate",
                                "min-width": "100",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "启用状态",
                                align: "center",
                                prop: "isStopName",
                                "min-width": "100",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            scope.row.isStop == true
                                              ? "停用"
                                              : "启用"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "单据状态",
                                align: "center",
                                prop: "bomStatusName",
                                "min-width": "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "版本号",
                                align: "center",
                                prop: "bomVer",
                                "min-width": "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "备注",
                                align: "center",
                                prop: "remark",
                                "min-width": "155",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "审核人",
                                align: "center",
                                prop: "auditBy",
                                "min-width": "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "审核时间",
                                align: "center",
                                prop: "auditTime",
                                "min-width": "155",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "修改人",
                                align: "center",
                                prop: "updateBy",
                                "min-width": "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "修改时间",
                                align: "center",
                                prop: "updateTime",
                                "min-width": "155",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "创建人",
                                align: "center",
                                prop: "createBy",
                                "min-width": "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "创建时间",
                                align: "center",
                                prop: "createTime",
                                "min-width": "155",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("bottomPage", {
                          attrs: {
                            getList: _vm.getList,
                            idsCom: _vm.ids,
                            totalCom: _vm.total,
                          },
                          on: { selectAllCom: _vm.selectAll },
                          model: {
                            value: _vm.queryParams,
                            callback: function ($$v) {
                              _vm.queryParams = $$v
                            },
                            expression: "queryParams",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c("to-channel", {
        attrs: { visible: _vm.openToChannel, downloadFile: _vm.downloadFile },
        on: {
          "update:visible": function ($event) {
            _vm.openToChannel = $event
          },
          upload: _vm.uploadFile,
        },
      }),
      _c("importError", {
        attrs: { errVisible: _vm.errVisible, errorList: _vm.errorList },
        on: {
          "update:errVisible": function ($event) {
            _vm.errVisible = $event
          },
          "update:err-visible": function ($event) {
            _vm.errVisible = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }