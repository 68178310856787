<template>
  <el-form ref="form" :model="serviceForm" label-width="96px" class="wrap" v-loading="loading" :rules="rules" id="wrap">
    <!-- 顶部按钮 -->
    <topOperatingButton :defaultProps='defaultProps' :isSubmitBtn='serviceForm.goodsType!=3'
      :isSubmitAddBtn='serviceForm.goodsType!=3' :isAuditBillBtn='serviceForm.goodsType!=3' :disabled="disabled"
      :showAudit="showAudit" :isAddBtn="showAdd" @submitForm="submitForm(true)" @addBill="addBill"
      @auditBill="auditBill" @handleAdd="handleAdd" @getQuit="getQuit" id="topOperatingButton">
      <!-- <template #specialDiyBtn>
        <el-button type="success" @click="submitProcess()" size="mini" :disabled="serviceForm.bomStatus != '2'">保存工序
        </el-button>
      </template> -->
    </topOperatingButton>
    <div class="x-f">
      <!-- 商品数据左边盒子 -->
      <categoryTreeCard bomAnnotation='双击进入该半成品配方' categoryTreeTitle="产品配方结构" @treeNodeId="getTreeNodeId"
        :isDoubleClick='isDoubleClick' @handleNodeDoubleClick='handleNodeDoubleClick'
        :categoryTreeOptions="categoryTreeOptions" :boxHeight="boxHeight" :boxInternalHeight="boxInternalHeight" />
      <!-- 基本信息右边盒子 -->
      <div class="rightBox y-b" v-loading="loadingGoods">
        <!-- 表格搜索条件 -->
        <cardTitleCom cardTitle="基本信息" :billStatus="serviceForm.bomStatus" id="search-card">
          <template slot="cardContent">
            <div class="x-w marT10">
              <!-- 商品信息 -->
              <!-- 第一行 -->
              <el-form-item label="配方编码" prop="bomNo">
                <el-input class="inputWidth" disabled v-model="serviceForm.bomNo" placeholder="配方编码"
                  size="mini"></el-input>
              </el-form-item>
              <el-form-item label="商品编码" prop="goodsId">
                <SelectRemote @selectChange="selectChangeGoods" v-model="serviceForm.goodsId" :option="
                    $select({
                      key: 'listBomGoods',
                      option: {
                        option: {
                          disabled: disabled||serviceForm.goodsType==3,
                            showItem: [
                          {
                            goodsId: serviceForm.goodsId,
                            goodsName: serviceForm.goodsNo,
                          },
                        ],
                        }
                      }
                    }).option
                  " />
              </el-form-item>
              <el-form-item label="商品名称" prop="goodsName">
                <el-input size="mini" class="inputWidth" :disabled="true" v-model="serviceForm.goodsName"
                  placeholder="商品名称"></el-input>
              </el-form-item>
              <el-form-item label="规格型号" prop="goodsSpec">
                <el-input size="mini" class="inputWidth" :disabled="true" v-model="serviceForm.goodsSpec"
                  placeholder="规格型号"></el-input>
              </el-form-item>
              <el-form-item label="版本号" prop="bomVer">
                <el-input size="mini" class="inputWidth" :disabled="true" v-model="serviceForm.bomVer"
                  placeholder="版本号"></el-input>
              </el-form-item>
              <el-form-item label="单位" prop="unitId">
                <el-select size="mini" v-model="serviceForm.unitId" :disabled="true" class="inputWidth">
                  <el-option v-for="item in serviceForm.units" :key="item.unitId" :label="item.unitName"
                    :value="item.unitId" />
                </el-select>
              </el-form-item>
              <el-form-item label="成品率(%)" prop="yieldRate">
                <el-input type="number" size="mini" class="inputWidth" :disabled="disabled||serviceForm.goodsType==3"
                  v-model="serviceForm.yieldRate" placeholder="成品率"></el-input>
              </el-form-item>
              <el-form-item :label="`${isRootNode ? '批产量' : '批用量'}`" prop="batchProdQty">
                <el-input type="number" size="mini" class="inputWidth" :disabled="disabled||serviceForm.goodsType==3"
                  @keyup.native="onkeyupBatchProdQty" v-model="serviceForm.batchProdQty"
                  :placeholder="`${isRootNode ? '批产量' : '批用量'}`"></el-input>
              </el-form-item>
              <el-form-item label="成本价" prop="oneCostMoney">
                <el-input size="mini" class="inputWidth" :disabled="true" v-model.number="serviceForm.oneCostMoney"
                  placeholder="成本价"></el-input>
              </el-form-item>
              <el-form-item label="批量成本" prop="batchCostMoney">
                <el-input size="mini" class="inputWidth" :disabled="true" v-model.number="serviceForm.batchCostMoney"
                  placeholder="批量成本"></el-input>
              </el-form-item>
              <el-form-item label="备注" prop="remark">
                <el-input size="mini" class="inputRemark" :disabled="disabled||serviceForm.goodsType==3"
                  v-model="serviceForm.remark" type="textarea" placeholder="备注长度介于 1 和 80 字符之间" maxlength="80"
                  :autosize="{ minRows: 1, maxRows: 5 }" />
              </el-form-item>
            </div>
          </template>
        </cardTitleCom>
        <!-- 表格商品信息卡片 -->
        <cardTitleCom :cardTitle="serviceForm.bomId?null:'基本信息'" class="headGoodsTable">
          <template slot="leftCardTitle" v-if="serviceForm.bomId">
            <el-radio-group v-model="tabType" size="mini" style="margin-right:10px">
              <el-radio-button :label="1">子件信息</el-radio-button>
              <el-radio-button :label="2">配方工序</el-radio-button>
            </el-radio-group>
          </template>
          <template slot="cardContent">
            <!-- 商品信息按钮和表格整体盒子 -->
            <div class="goodsMessage" v-if="serviceForm.goodsType!=3">
              <el-button style="margin-bottom:5px; " v-if="serviceForm.bomId && tabType == 2" type="success"
                @click="submitProcess()" size="mini" :disabled="serviceForm.bomStatus != '2'">保存工序
              </el-button>
              <el-table v-show="tabType==1" ref="multipleTable1" :data="serviceForm.bomDetail" border
                @cell-mouse-enter="cellMouseEnter" @cell-mouse-leave="cellMouseLeave" :max-height="tableHeight"
                :height="tableHeight" show-summary :summary-method="getSummaries">
                <el-table-column align="center" :label="tableCellLabel" width="80">
                  <template v-slot="scope">
                    <i v-if="scope.row.hoverRow" :disabled="disabled" @click="row('push', scope.$index)"
                      class="el-icon-circle-plus operatePush"></i>
                    <i v-if="scope.row.hoverRow" :disabled="disabled" @click="row('del', scope.$index)"
                      class="el-icon-remove operateDel"></i>
                    <div v-if="!scope.row.hoverRow">{{ scope.$index + 1 }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="商品编码" min-width="180" align="center" prop="goodsNo">
                  <template slot="header">
                    <i style="color: #ff4949">* </i>
                    <span>商品编码</span>
                  </template>
                  <template slot-scope="scope">
                    <el-form-item :prop="'bomDetail.' + scope.$index + '.goodsNo'"
                      :rules="rules[`bomDetail.goodsNoMaterial`]">
                      <SelectRemote size="mini" :disabled="disabled" v-model="scope.row.goodsNo" :option="optionGoods"
                        :ref="'goodsNo'+'_'+scope.$index +'_1'" :filterable="true" placeholder="选择商品" />
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="商品名称" align="center" prop="goodsName" show-overflow-tooltip min-width="120">
                </el-table-column>
                <el-table-column label="规格" align="center" prop="goodsSpec" show-overflow-tooltip min-width="100">
                </el-table-column>
                <el-table-column label="版本号" align="center" prop="bomVer" show-overflow-tooltip min-width="80">
                </el-table-column>
                <el-table-column label="单位" align="center" prop="unitId" min-width="120">
                  <template slot="header">
                    <i style="color: #ff4949">* </i>
                    <span>单位</span>
                  </template>
                  <template slot-scope="scope">
                    <el-form-item :prop="'bomDetail.' + scope.$index + '.unitId'"
                      :rules="rules[`bomDetail.unitIdMaterial`]">
                      <el-select size="mini" @change="unitPriceChange($event, scope.row)" v-model="scope.row.unitId"
                        :disabled="disabled">
                        <el-option v-for="item in scope.row.units" :key="item.unitId" :label="item.unitName"
                          :value="item.unitId" />
                      </el-select>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="单个用量" align="center" min-width="120" prop="oneUseQty">
                  <template slot="header">
                    <i style="color: #ff4949">* </i>
                    <span>单个用量</span>
                  </template>
                  <template slot-scope="scope">
                    <el-form-item :prop="'bomDetail.' + scope.$index + '.oneUseQty'"
                      :rules="rules[`bomDetail.oneUseQtyMaterial`]">
                      <el-input type="number" maxlength="15" size="mini" @keyup.native="onkeyupOneUseQty(scope.row, 1)"
                        v-model="scope.row.oneUseQty" :disabled="disabled"></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="单个用量(克)" align="center" prop="oneUseQtyGram" min-width="120">
                  <template slot="header">
                    <i style="color: #ff4949">* </i>
                    <span>单个用量(克)</span>
                  </template>
                  <template slot-scope="scope">
                    <el-form-item :prop="'bomDetail.' + scope.$index + '.oneUseQtyGram'"
                      :rules="rules[`bomDetail.oneUseQtyMaterial`]">
                      <el-input type="number" maxlength="15" size="mini" @keyup.native="onkeyupOneUseQty(scope.row, 2)"
                        v-model="scope.row.oneUseQtyGram" :disabled="disabled"></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="成本价" align="center" show-overflow-tooltip prop="oneCostPrice" min-width="120" />
                <el-table-column label="成本" align="center" show-overflow-tooltip prop="oneCostMoney" min-width="120">
                </el-table-column>
                <el-table-column label="批产量用量" align="center" prop="batchUseQty" min-width="120">
                  <template slot="header">
                    <i style="color: #ff4949">* </i>
                    <span>批产量用量</span>
                  </template>
                  <template slot-scope="scope">
                    <el-form-item :prop="'bomDetail.' + scope.$index + '.batchUseQty'"
                      :rules="rules[`bomDetail.batchUseQty`]">
                      <el-input type="number" maxlength="15" size="mini"
                        @keyup.native="onkeyupBatchUseQty(scope.row, 1)" v-model="scope.row.batchUseQty"
                        :disabled="disabled"></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="批产量用量(克)" align="center" prop="batchUseQtyGram" min-width="120">
                  <template slot="header">
                    <i style="color: #ff4949">* </i>
                    <span>批产量用量(克)</span>
                  </template>
                  <template slot-scope="scope">
                    <el-form-item :prop="'bomDetail.' + scope.$index + '.batchUseQtyGram'"
                      :rules="rules[`bomDetail.batchUseQtyGram`]">
                      <el-input type="number" maxlength="15" size="mini"
                        @keyup.native="onkeyupBatchUseQty(scope.row, 2)" v-model="scope.row.batchUseQtyGram"
                        :disabled="disabled"></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="批量成本" align="center" prop="batchCostMoney" min-width="120" />
                <el-table-column label="损耗率(%)" align="center" prop="loseRate" min-width="100">
                  <template slot-scope="scope">
                    <el-form-item :prop="'bomDetail.' + scope.$index + '.loseRate'"
                      :rules="rules[`bomDetail.loseRateMaterial`]">
                      <el-input type="number" size="mini" v-model="scope.row.loseRate" :disabled="disabled"
                        maxlength="8"></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="备注" align="center" prop="remark" min-width="155">
                  <template slot-scope="scope">
                    <el-input size="mini" v-model="scope.row.remark" :disabled="disabled" maxlength="80"></el-input>
                  </template>
                </el-table-column>
              </el-table>
              <el-table v-show="tabType==2" ref="multipleTable2" :data="bomProcessSettingList" border
                :max-height="tableHeight" :height="tableHeight">
                <el-table-column label="序号" align="center" width="80" type="index" />
                <el-table-column label="商品编码" align="center" prop="goodsNo" min-width="120" />
                <el-table-column label="商品名称" align="center" prop="goodsName" min-width="120" />
                <el-table-column label="规格" align="center" prop="goodsSpec" min-width="100" />
                <el-table-column label="单位" align="center" prop="unitName" min-width="80" />
                <template
                  v-for="(item,index) in (bomProcessSettingList.length>0&&bomProcessSettingList[0].list?bomProcessSettingList[0].list:[])">
                  <el-table-column :label="item.processInfo.processName" align="center" width="200"
                    :key="`${index},${item.processInfo.processId},${getUid()}`">
                    <el-table-column align="center" width="80">
                      <template slot="header">
                        <div>
                          启用
                        </div>
                      </template>
                      <template slot-scope="scope">
                        <el-checkbox v-model="scope.row.list[index].isUse"
                          :disabled="serviceForm.bomStatus != '2'"></el-checkbox>
                      </template>
                    </el-table-column>
                    <el-table-column align="center" width="80">
                      <template slot="header">
                        <div>
                          生产
                        </div>
                      </template>
                      <template slot-scope="scope">
                        <el-checkbox v-model="scope.row.list[index].isProduce"
                          :disabled="serviceForm.bomStatus != '2'"></el-checkbox>
                      </template>
                    </el-table-column>
                    <el-table-column align="center" width="80">
                      <template slot="header">
                        <div>
                          交接
                        </div>
                      </template>
                      <template slot-scope="scope">
                        <el-checkbox v-model="scope.row.list[index].isHandover"
                          :disabled="serviceForm.bomStatus != '2'"></el-checkbox>
                      </template>
                    </el-table-column>
                  </el-table-column>
                </template>
              </el-table>
            </div>
          </template>
        </cardTitleCom>
      </div>
    </div>
  </el-form>
</template>

<script>
import categoryTreeCard from '@/views/components/categoryTreeCard' //选择类别
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import { getBillNo } from '@/api/codeRule' //单据号
import {
  serviceAddAPI,
  serviceDetailAPI,
  serviceUpdateAPI,
  serviceGoodsTreeAPI,
  serviceUpdateStatusAPI,
  getBomProcessSetting,
  saveBomProcess,
  getSubBomInfo
} from '@/api/produce/bom/service' //生产配方接口
import topOperatingButton from '@/views/components/topOperatingButton' //顶部按钮
import SelectRemote from '@/components/tablePage/select/select-remote/index.vue'
import { deepCopy, getUid, fcount, isNumber } from '@/utils'

export default {
  name: 'serviceDetail',
  dicts: ['App_is_use', 'bill_status'],
  components: {
    categoryTreeCard,
    cardTitleCom,
    topOperatingButton,
    SelectRemote,
  },
  data() {
    return {
      defaultProps: {
        id: "id",
        children: "children",
        label: "label",
        parentId: "parentId",
        tag: "tag",
      },
      isDoubleClick: false,
      optionGoods: this.$select({
        key: "listGoods",
        option: {
          option: {
            noEcho: true,
            remoteBody: { goodsTypes: [2, 3] },
            label: "goodsNo",
            change: (propValue, row) => {
              if (propValue) {
                const findIndex = this.serviceForm.bomDetail.findIndex(
                  (item) => item.goodsNo === propValue
                );
                let rows = deepCopy(row);
                rows.findIndex = findIndex;
                this.getGoodS([rows]);
              }
            },
            tableChange: (propValue, row) => {
              this.getGoodS(row.check);
            },
            buttons: [
              {
                type: "more",
                option: {
                  title: "选择商品",
                  width: 1250,
                  type: "TreeAndTable",
                  formData: this.$dialog({
                    key: "goods",
                    option: {
                      queryParams: { goodsTypes: [2, 3] },
                      table: {
                        ...this.$dialog({ key: "goods" }).table,
                      },
                    },
                  }),
                },
              },
            ],
          },
        },
      }).option,
      rootNodeBomId: '', //根节点id
      subBomId: '', //节点id
      tabType: 1,
      bomProcessSetting: {}, //获取配方的工序
      bomProcessSettingList: [], //获取配方的表格工序
      tableCellLabel: '序号',
      isSelectGoodsRadio: false, //商品弹框单选
      indexTreeId: undefined, //第一次的bomId
      indexTree: 0, //当前bom级别
      categoryTreeOptions: [], //树的数据
      //自定义搜索框字段转换 (供应商)
      normalizerGoods: {
        Id: 'goodsId',
        No: 'goodsNo',
        Name: 'goodsName'
      },
      oldForm: {}, //旧的表单
      disabled: false, //表单禁用
      showAdd: false, //新增按钮
      showAudit: true, //审核按钮禁用状态
      // 遮罩层
      loading: false,
      //商品遮罩
      loadingGoods: false,
      // 表单校验
      rules: {
        bomNo: [
          {
            required: true,
            message: '配方编码不能为空',
            trigger: ['blur', 'change']
          }
        ],
        goodsId: [
          {
            required: true,
            message: '商品编码不能为空',
            trigger: ['blur', 'change']
          } //仓库地址
        ],
        unitId: [
          {
            required: true,
            message: '商品单位不能为空',
            trigger: ['blur', 'change']
          } //负责人
        ],
        yieldRate: [
          {
            required: true,
            message: '商品成品率不能为空',
            trigger: ['blur', 'change']
          },
          { pattern: /^\d{0,3}(\.\d{0,2})?$/, message: '超出输入字符范围' }
        ],
        batchProdQty: [
          {
            required: true,
            message: '商品批产量不能为空',
            trigger: ['blur', 'change']
          },
          {
            pattern: /^\d{0,8}(\.\d{0,8})?$/,
            message: '超出输入字符范围'
          }
        ],
        'bomDetail.goodsNoMaterial': [
          {
            required: true,
            message: '原料编码不能为空',
            trigger: ['blur', 'change']
          }
        ],
        'bomDetail.unitIdMaterial': [
          {
            required: true,
            message: '原料单位不能为空',
            trigger: ['blur', 'change']
          }
        ],
        'bomDetail.oneUseQtyMaterial': [
          {
            required: true,
            message: '原料用量不能为空',
            trigger: ['blur', 'change']
          },
          {
            pattern: /^\d{0,8}(\.\d{0,8})?$/,
            message: '超出输入字符范围'
          }
        ],
        'bomDetail.batchUseQty': [
          {
            required: true,
            message: '批产量用量不为空',
            trigger: ['blur', 'change']
          },
          {
            pattern: /^\d{0,8}(\.\d{0,8})?$/,
            message: '超出输入字符范围'
          }
        ],
        'bomDetail.batchUseQtyGram': [
          {
            required: true,
            message: '批产量用量(克)不为空',
            trigger: ['blur', 'change']
          },
          {
            pattern: /^\d{0,8}(\.\d{0,8})?$/,
            message: '超出输入字符范围'
          }
        ],
        'bomDetail.loseRateMaterial': [
          {
            pattern: /^\d{0,3}(\.\d{0,2})?$/,
            message: '超出输入字符范围',
            trigger: ['blur', 'change']
          }
        ]
      },
      // 表单参数
      serviceForm: {
        bomStatus: '',
        bomDetail: [{ hoverRow: false }]
      },
      vivwH: 0, //页面高度
      searchH: 0 //搜索框高度
    }
  },
  watch: {
    //侦听是否开启关闭按钮
    'serviceForm.bomStatus': {
      handler(newVal) {
        this.performAction()
      },
      immediate: true
    }
  },
  beforeRouteEnter(to, from, next) {
    next(async vm => {
      vm.loading = true
      // 访问组件实例 vm 中的数据和方法
      vm.reset()
      if (vm.$route.query.type === 'Update') {
        if (vm.$route.query.bomId) {
          //获取单据详情
          const res1 = await serviceDetailAPI(vm.$route.query.bomId)
          //单据赋值
          vm.serviceForm = res1.data
          vm.indexTree += 1
          vm.indexTreeId = vm.$route.query.bomId
          vm.subBomId = vm.$route.query.bomId
          //配方树
          vm.isDoubleClick = false;
          const res2 = await serviceGoodsTreeAPI(vm.$route.query.bomId)
          vm.categoryTreeOptions = res2
          vm.rootNodeBomId = vm.categoryTreeOptions?.[0]?.id
          vm.performAction()
          setTimeout(() => {
            vm.isDoubleClick = true;
          }, 400);
          //获取配方的工序
          const res3 = await getBomProcessSetting(vm.$route.query.bomId)
          vm.bomProcessSetting = res3.data
          vm.bomProcessSettingList = vm.bomProcessSetting?.goodsBoms?.map(x => {
            let arr = vm.bomProcessSetting?.bomProcesses?.filter(v => v.bomId == x.bomId)
            let craftLineProcesses = vm.bomProcessSetting?.craftLineGoods?.craftLineProcesses ? deepCopy(vm.bomProcessSetting.craftLineGoods.craftLineProcesses) : []
            x.list = craftLineProcesses.map(y => {
              let obj = arr.find(z => (z.craftLineProcessLineId == y.lineId))
              if (obj) {
                y.isUse = obj.isUse
                y.isHandover = obj.isHandover
                y.isProduce = obj.isProduce
              }
              return {
                isUse: false, isHandover: false, isProduce: false,
                ...y
              }
            })
            return {
              ...x,
              ...vm.bomProcessSetting.craftLineGoods,
              // ...obj,
            }
          })
          //原始单据赋值
          vm.oldForm = JSON.parse(JSON.stringify(vm.serviceForm))
        }
      } else {
        //获取单据编码
        await vm.getOrderBillNo()
        vm.disabled = false
        //判断是否有以前新增时保存的数据
        if (JSON.stringify(vm.oldForm) != '{}') {
          vm.serviceForm = vm.oldForm
        }
        vm.oldForm = JSON.parse(JSON.stringify(vm.serviceForm))
      }
      vm.loading = false
    })
  },

  async beforeRouteLeave(to, from, next) {
    // 在用户离开二级路由前弹出询问框
    if (this.serviceForm.bomId) {
      this.oldForm = {}
    } else {
      this.oldForm = JSON.parse(JSON.stringify(this.serviceForm))
    }
    next()
  },
  computed: {
    isRootNode() {
      return (this.rootNodeBomId == this.subBomId) || (!this.rootNodeBomId)
    },
    tableHeight() {
      return this.vivwH - this.searchH - 105 - 33
    },
    boxHeight() {
      return this.vivwH - 40
    },
    boxInternalHeight() {
      return this.vivwH - 68
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    performAction() {
      if (!this.isRootNode) {
        this.showAdd = true;
        this.disabled = true;
        this.showAudit = true;
      } else {
        switch (this.serviceForm.bomStatus) {
          case '2':
            this.showAdd = true;
            this.disabled = true;
            this.showAudit = false;
            break;
          case '3':
            this.showAdd = false;
            this.showAudit = true;
            this.disabled = true;
            break;
          case '0':
          case '4':
            this.showAdd = false;
            this.showAudit = false;
            this.disabled = false;
            break;
          case '':
            this.showAdd = false;
            this.showAudit = true;
            this.disabled = false;
            break;
          default:
            break;
        }
      }
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        const other = ["oneUseQty", "oneCostMoney", 'batchUseQty', 'batchUseQtyGram', 'batchCostMoney', 'oneUseQtyGram'];
        const [key, orderStoreId] = column.property?.split?.(".") || [];
        if (other.includes(column.property) || other.includes(key)) {
          let values = data.map((x) => {
            let num = Number(x[column.property] || 0);
            if (orderStoreId) {
              num = Number(
                x.storeItemCache?.find?.((x) => x.orderStoreId === orderStoreId)?.[key] ||
                0
              );
            }
            return num;
          });
          sums[index] = values.reduce(
            (p, c) => fcount([p, isNumber(Number(c)) ? Number(c) : 0], "+"),
            0
          );
        } else {
          sums[index] = "";
        }
      });
      return sums;
    },
    getUid,
    //保存工序
    async submitProcess() {
      let arr = deepCopy(this.bomProcessSetting.goodsBoms)
      let array = []
      arr.forEach(v => {
        let arr2 = v.list.filter(x => x.isUse || x.isHandover || x.isProduce)
        arr2.forEach(x => {
          array.push({
            craftLineId: x.craftLineId,
            craftLineProcessLineId: x.lineId,
            processId: x.processId,
            processName: x.processName,
            isUse: x.isUse,
            isHandover: x.isHandover,
            isProduce: x.isProduce,
            bomId: v.bomId,
            parentBomId: v.parentBomId,
            rootBomId: v.rootBomId,
            goodsId: v.goodsId,
            goodsName: v.goodsName,
            unitId: v.unitId,
          })
        })
      })
      await saveBomProcess(array)
      this.$message({
        type: 'success',
        message: '保存工序成功'
      })
    },
    selectChangeGoods(val, value) {
      this.$set(this.serviceForm, 'goodsId', value?.goodsId)
      this.$set(this.serviceForm, 'goodsNo', value?.goodsNo)
      this.$set(this.serviceForm, 'goodsName', value?.goodsName)
      this.$set(this.serviceForm, 'goodsSpec', value?.goodsSpec)
      this.$set(this.serviceForm, 'unitId', value?.unitId)
      this.$set(this.serviceForm, 'units', value?.units)
      //批产量
      if (!this.serviceForm.batchProdQty) {
        this.$set(this.serviceForm, 'batchProdQty', 1)
      }
      //成品率
      if (!this.serviceForm.yieldRate) {
        this.$set(this.serviceForm, 'yieldRate', 100)
      }
    },
    handleResize() {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    },
    //表格行hover时,显示操作加减号
    cellMouseEnter(row) {
      if (
        this.serviceForm.bomStatus == '0' ||
        this.serviceForm.bomStatus == ''
      ) {
        let eleIndex = this.serviceForm.bomDetail.indexOf(row)
        for (
          let index = 0;
          index < this.serviceForm.bomDetail.length;
          index++
        ) {
          const element = this.serviceForm.bomDetail[index]
          if (eleIndex == index) {
            element['hoverRow'] = true
          } else {
            element['hoverRow'] = false
          }
        }
        this.tableCellLabel = '操作'
      }
    },
    //表格行离开hover时,不显示操作加减号,显示序号
    cellMouseLeave() {
      // 移除hover的事件
      for (let index = 0; index < this.serviceForm.bomDetail.length; index++) {
        const element = this.serviceForm.bomDetail[index]
        element['hoverRow'] = false
      }
      this.tableCellLabel = '序号'
    },
    //新增按钮
    async handleAdd() {
      this.loading = true
      this.reset()
      await this.getOrderBillNo()
      this.loading = false
    },
    //成品批量成本 计算公式
    totalCostBatch() {
      let num = 0
      let arr = []
      this.serviceForm.bomDetail.forEach(v => {
        if (v.batchCostMoney >= 0) {
          v.batchCostMoney = parseFloat(v.batchCostMoney)
          arr.push(v.batchCostMoney)
        }
      })
      num = arr.reduce((acc, curr) => acc + curr, 0)
      num = this.formatNumber(num, 8)
      this.$set(this.serviceForm, 'batchCostMoney', num)
    },
    //成品成本 计算公式
    totalCost() {
      let num = 0
      let arr = []
      this.serviceForm.bomDetail.forEach(v => {
        if (v.oneCostMoney >= 0) {
          v.oneCostMoney = parseFloat(v.oneCostMoney)
          arr.push(v.oneCostMoney)
        }
      })
      num = arr.reduce((acc, curr) => acc + curr, 0)
      num = this.formatNumber(num, 8)
      this.$set(this.serviceForm, 'oneCostMoney', num)
    },

    //批产量*用量=批产量用量  批产量成本  计算公式
    batchProductionYes() {
      if (this.serviceForm.bomDetail.length > 0 && this.serviceForm.bomDetail) {
        this.serviceForm.bomDetail.forEach(v => {
          if (this.serviceForm.batchProdQty >= 0 && v.oneUseQty >= 0) {
            let batchUseQty = v.oneUseQty * this.serviceForm.batchProdQty
            batchUseQty = this.formatNumber(batchUseQty, 8)
            this.$set(v, 'batchUseQty', batchUseQty)
            if (v.pricingMode == 1) {
            this.$set(v, 'batchUseQtyGram', fcount([v.batchUseQty, 1000], '*'))
            }else {
            this.$set(v, 'batchUseQtyGram', v.batchUseQty)
            }
          }
          //原料批产量成本
          if (v.batchUseQty >= 0 && v.oneCostPrice >= 0) {
            let batchCostMoney = v.batchUseQty * v.oneCostPrice
            batchCostMoney = this.formatNumber(batchCostMoney, 8)
            this.$set(v, 'batchCostMoney', batchCostMoney)
          }
        })
      }
    },
    //批产量用量/批产量=用量  计算公式
    batchProductionNo() {
      if (this.serviceForm.bomDetail.length > 0 && this.serviceForm.bomDetail) {
        this.serviceForm.bomDetail.forEach(v => {
          if (this.serviceForm.batchProdQty >= 0 && v.batchUseQty >= 0) {
            let oneUseQty = v.batchUseQty / this.serviceForm.batchProdQty
            oneUseQty = this.formatNumber(oneUseQty, 8)
            this.$set(v, 'oneUseQty', oneUseQty)
            let oneUseQtyGram = oneUseQty
            if (v.pricingMode == 1 && v.goodsType == 3) {
              oneUseQtyGram = fcount([oneUseQty, 1000], '*')
            }
            this.$set(v, 'oneUseQtyGram', oneUseQtyGram)
          }
          //原料成本
          if (v.oneUseQty >= 0 && v.oneCostPrice >= 0) {
            let oneCostMoney = v.oneUseQty * v.oneCostPrice
            oneCostMoney = this.formatNumber(oneCostMoney, 8)
            this.$set(v, 'oneCostMoney', oneCostMoney)
          }
        })
      }
    },
    //退出
    async getQuit() {
      if (!this.indexTreeId) {
        // 关闭当前tab页签，打开新页签
        this.$tab.closeOpenPage({ path: '/produce/bom/service' })
        return
      }
      const res = await serviceDetailAPI(this.indexTreeId)
      if (res.data.bomStatus == '0') {
        let goodsName = this.categoryTreeOptions[0].label
        this.$confirm(`${goodsName}还未审核, 是否审核?`, '提示', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            const obj = {
              bomIds: [this.indexTreeId],
              bomStatus: '2'
            }
            //发送审核api
            serviceUpdateStatusAPI(obj)
            // 弹出提示
            this.$message.success('审核成功')
            // 关闭当前tab页签，打开新页签
            this.$tab.closeOpenPage({ path: '/produce/bom/service' })
          })
          .catch(() => {
            // 关闭当前tab页签，打开新页签
            this.$tab.closeOpenPage({ path: '/produce/bom/service' })
          })
      } else if (res.data.bomStatus != '0') {
        // 关闭当前tab页签，打开新页签
        this.$tab.closeOpenPage({ path: '/produce/bom/service' })
      }
    },
    //单批产量用量键盘抬起
    onkeyupBatchUseQty(row, type = 1) {
      if (type == 1) {
        let batchUseQtyGram = row.batchUseQty
        if (row.pricingMode == 1) {
          batchUseQtyGram = fcount([row.batchUseQty, 1000], '*')
        }
        this.$set(row, 'batchUseQtyGram', batchUseQtyGram)
      } else {
        let batchUseQty = row.batchUseQtyGram
        if (row.pricingMode == 1) {
          batchUseQty = fcount([row.batchUseQtyGram, 1000], '/')
        }
        this.$set(row, 'batchUseQty', batchUseQty)
      }
      //批产量用量
      if (row.batchUseQty >= 0 && row.oneCostPrice >= 0) {
        let batchCostMoney = row.batchUseQty * row.oneCostPrice
        batchCostMoney = this.formatNumber(batchCostMoney, 8)
        this.$set(row, 'batchCostMoney', batchCostMoney)
      }
      //批产量用量/批产量=用量
      this.batchProductionNo()
      //成品成本
      this.totalCost()
      //成品批产量成本
      this.totalCostBatch()
    },
    //原料用量键盘抬起
    onkeyupOneUseQty(row, type = 1) {
      if (type == 1) {
        let oneUseQtyGram = row.oneUseQty
        if (row.pricingMode == 1) {
          oneUseQtyGram = fcount([row.oneUseQty, 1000], '*')
        }
        this.$set(row, 'oneUseQtyGram', oneUseQtyGram)
      } else {
        let oneUseQty = row.oneUseQtyGram
        if (row.pricingMode == 1) {
          oneUseQty = fcount([row.oneUseQtyGram, 1000], '/')
        }
        this.$set(row, 'oneUseQty', oneUseQty)
      }
      if (row.oneUseQty >= 0 && row.oneCostPrice >= 0) {
        let oneCostMoney = row.oneUseQty * row.oneCostPrice
        oneCostMoney = this.formatNumber(oneCostMoney, 8)
        this.$set(row, 'oneCostMoney', oneCostMoney)
      }
      //批产量*用量=批产量用量
      this.batchProductionYes()
      //成品成本
      this.totalCost()
      //成品批产量成本
      this.totalCostBatch()
    },
    //成品批产量键盘抬起
    onkeyupBatchProdQty() {
      //批产量*用量=批产量用量
      this.batchProductionYes()
      //成品批产量成本
      this.totalCostBatch()
    },
    //多级单位换算
    unitPriceChange(value, row) {
      row.units.forEach(v => {
        if (v.unitId === value) {
          this.$set(row, 'oneCostPrice', v.unitPrice)
          const oneCostMoney = row.oneUseQty * row.oneCostPrice
          this.$set(row, 'oneCostMoney', oneCostMoney)
          const batchCostMoney = row.batchUseQty * row.oneCostPrice
          this.$set(row, 'batchCostMoney', batchCostMoney)
        }
      })
    },
    //点击类别树
    async getTreeNodeId(value, type) {
      if (!this.isDoubleClick) return
      if (!this.loadingGoods) {
        this.loadingGoods = true
      }
      //获取单据详情
      this.subBomId = value
      const res1 = await getSubBomInfo({ rootBomId: this.rootNodeBomId, subBomId: value })
      //单据赋值
      this.serviceForm = res1.data
      await this.getOrderBillNo()
      //判断原料是否为空
      if (
        (this.serviceForm.bomDetail.length <= 0 ||
          this.serviceForm.bomDetail == undefined) &&
        this.serviceForm.goodsType != '3'
      ) {
        this.serviceForm.bomDetail = [{}]
      }
      //原始单据赋值
      this.oldForm = JSON.parse(JSON.stringify(this.serviceForm))
      this.performAction()
      if (this.loadingGoods) {
        this.loadingGoods = false
      }
    },
    async handleNodeDoubleClick(value, data) {
      if (data?.tag?.goodsType != 2) return;
      try {
        this.loadingGoods = true
        //获取单据详情
        const res1 = await serviceDetailAPI(value)
        //单据赋值
        this.serviceForm = res1.data
        this.indexTree += 1
        this.indexTreeId = value
        this.subBomId = value
        //配方树
        this.isDoubleClick = false;
        const res2 = await serviceGoodsTreeAPI(value)
        this.categoryTreeOptions = res2
        this.rootNodeBomId = this.categoryTreeOptions?.[0]?.id
        this.performAction()
        setTimeout(() => {
          this.isDoubleClick = true;
        }, 400);
        //获取配方的工序
        const res3 = await getBomProcessSetting(value)
        this.bomProcessSetting = res3.data
        this.bomProcessSettingList = this.bomProcessSetting?.goodsBoms?.map(x => {
          let arr = this.bomProcessSetting?.bomProcesses?.filter(v => v.bomId == x.bomId)
          let craftLineProcesses = this.bomProcessSetting?.craftLineGoods?.craftLineProcesses ? deepCopy(this.bomProcessSetting.craftLineGoods.craftLineProcesses) : []
          x.list = craftLineProcesses.map(y => {
            let obj = arr.find(z => (z.craftLineProcessLineId == y.lineId))
            if (obj) {
              y.isUse = obj.isUse
              y.isHandover = obj.isHandover
              y.isProduce = obj.isProduce
            }
            return {
              isUse: false, isHandover: false, isProduce: false,
              ...y
            }
          })
          return {
            ...x,
            ...this.bomProcessSetting.craftLineGoods,
            // ...obj,
          }
        })
        //原始单据赋值
        this.oldForm = JSON.parse(JSON.stringify(this.serviceForm))
        this.loadingGoods = false
      } catch (error) {
        this.loadingGoods = false
      }
    },
    //选中的商品
    getGoodS(value) {
      //去重
      value = value.filter(item => {
        return this.serviceForm.bomDetail.every(v => v.goodsId != item.goodsId)
      })
      value.forEach(v => {
        this.$set(v, 'hoverRow', false)
        this.$set(v, 'unitQty', 0)
        this.$set(v, 'unitMoney', 0)
        this.$set(v, 'loseRate', 0)
        this.$set(v, 'unitPrice', v.purPrice)
        this.$set(v, 'oneCostPrice', v.purPrice)
        v.remark = ''
        v.unitMoney = v.unitQty * v.unitPrice
      })
      const findIndex = value?.[0]?.findIndex;
      if (value.length == 1 && findIndex != undefined) {
        this.serviceForm.bomDetail[findIndex] = value[0];
      } else {
        this.serviceForm.bomDetail.push(...value);
      }
      this.serviceForm.bomDetail = this.serviceForm.bomDetail.filter(
        item =>
          (!(JSON.stringify(item) === '{}') && !(item.goodsId === undefined)) ||
          !(item.goodsId === undefined)
      )
      if (this.serviceForm.bomDetail.length == 0) {
        this.serviceForm.bomDetail = [{ hoverRow: false, }]
      }
    },

    //表格增加/减少一行方法
    row(name, index) {
      if (name === 'push') {
        this.serviceForm.bomDetail.splice(index + 1, 0, { hoverRow: false })
      } else {
        if (this.serviceForm.bomDetail.length <= 1) {
          this.serviceForm.bomDetail = [{ hoverRow: false }]
        } else {
          this.serviceForm.bomDetail.splice(index, 1)
        }
      }
    },

    //审核/删除/终止事件
    async auditBill(name) {
      try {
        await this.$confirm(`确定${name}该订单, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'success'
        })

        if (name === '审核') {
          if (this.serviceForm.bomStatus != '0') {
            this.$message.error('此单据不是未审核的状态哦~')
            return
          }
          const obj = {
            bomIds: [this.serviceForm.bomId],
            bomStatus: '2'
          }
          //发送审核api
          const { data } = await serviceUpdateStatusAPI(obj)
          this.serviceForm = data
          // 弹出提示
          this.$message.success('审核成功')
        } else if (name === '反审核') {
          if (this.serviceForm.bomStatus != '2') {
            this.$message.error('此单据不是已审核的状态哦~')
            return
          }
          const obj = {
            bomIds: [this.serviceForm.bomId],
            bomStatus: '0'
          }
          //发送反审核api
          const { data } = await serviceUpdateStatusAPI(obj)
          this.serviceForm = data
          // 弹出提示
          this.$message.success('反审核成功')
        }
      } catch { }
    },
    //保存并新增单据
    async addBill() {
      this.$confirm('是否保存并新增单据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'success'
      })
        .then(async () => {
          this.loading = true
          await this.submitForm(false)
          this.loading = false
        })
        .catch()
    },

    async getOrderBillNo() {
      //新增时获取单据编码
      const res = await getBillNo(170101)
      this.$set(this.serviceForm, 'bomNo', res)
    },
    // 表单重置
    reset() {
      this.subBomId = undefined
      this.indexTreeId = undefined
      this.indexTree = 0
      this.categoryTreeOptions = [] //树的数据
      this.serviceForm = {
        bomStatus: '',
        bomDetail: [{ hoverRow: false }]
      }
      this.resetForm('form')
    },

    /** 提交按钮 */
    submitForm: function (isBool) {
      this.$refs['form'].validate(async valid => {
        if (valid) {
          try {
            this.loading = true
            if (this.serviceForm.bomId != undefined) {
              const res = await serviceUpdateAPI(this.serviceForm)

              if (isBool) {
                //重新赋值给表单
                this.serviceForm = res.data
                this.$modal.msgSuccess('修改生产配方成功')
              } else {
                this.$modal.msgSuccess('保存并新增生产配方成功')
              }
            } else {
              const res = await serviceAddAPI(this.serviceForm)
              if (isBool) {
                //重新赋值给表单
                this.serviceForm = res.data
                this.$modal.msgSuccess('新增生产配方成功')
              } else {
                this.$modal.msgSuccess('保存并新增生产配方成功')
              }
            }
            if (isBool) {
              //类别树
              this.indexTree += 1
              if (this.indexTree == 1) {
                this.indexTreeId = this.serviceForm.bomId
              }
              this.isDoubleClick = false;
              const res = await serviceGoodsTreeAPI(this.indexTreeId)
              this.categoryTreeOptions = res
              this.rootNodeBomId = this.categoryTreeOptions?.[0]?.id
              this.subBomId = this.rootNodeBomId
              this.performAction()
              setTimeout(() => {
                this.isDoubleClick = true;
              }, 400);
            } else {
              //保存并新增
              this.reset()
              await this.getOrderBillNo()
            }
          } finally {
            if (this.loading) {
              this.loading = false
            }
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
//整体盒子
.wrap {
  width: 100%;
  padding: 40px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  // 右边
  .rightBox {
    height: calc(100vh - 125px);
    width: calc(100% - 362px); //商品搜索条件
    .headGoodsTable {
      // height: calc(100% - 136px);
      margin-bottom: 0;
      flex: 1;
      .goodsMessage {
        margin: 6px 10px 0 10px;
        ::v-deep .el-form-item__content {
          margin-left: 0 !important;
        }
        ::v-deep .el-form-item__error {
          position: static;
        }
        ::v-deep .el-form-item {
          margin-bottom: 0;
        }
      }
    }
  }
}
::v-deep .el-form-item {
  margin-bottom: 15px;
}
//输入框原生属性
/**
 * 解决el-input设置类型为number时，中文输入法光标上移问题
 **/
::v-deep .el-input__inner {
  line-height: 1px !important;
}

/**
 * 解决el-input设置类型为number时，去掉输入框后面上下箭头
 **/
::v-deep input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::v-deep .el-input__suffix-inner {
  z-index: 11;
  height: 100%;
  display: inline-block;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
