<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" :key="tableKey"> </TablePage>
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import { produceListAPI } from '@/api/system/maintain/param/produceParam'
import { listMaterialCost, listGoodsCost, updateGoodsPurPrice, updateOneCostPrice } from '@/api/produce/bom/renewCost' //批量修改
export default {
  name: "renewCost",
  components: {
    TablePage,
  },
  data() {
    return {
      tableKey: Date.now(),
      parameters: null,
      parametersObj: {
        '1': '标准进价',
        '2': '最新进价',
        '3': '最新成本价'
      },
      options: {
        summary: "",
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        body: {},
        rowKey: "goodsId",
        mutiSelect: true, // 多选
        check: [], // 选中数据
        search: [
          {
            type: "filters",
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "goodsNos", label: "商品编码" },
              { filter: "goodsNames", label: "商品名称" },
              { filter: "barcodes", label: "条码" },
            ],
          },
          this.$select({
            key: "listGoods",
            option: {
              filter: "goodsIds",
              label: "商品",
              // seniorSearch: true,
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择商品",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "goods" }),
                    },
                  },
                ],
              },
            },
          }),
          {
            type: "radio",
            label: "",
            filter: "isChangeCostPrice",
            noReset: true,
            model: false,
            change: (v) => {
              this.$refs.tablePage.getList();
            },
            hide: () => this.options?.tabColumnType == "materials",
            option: {
              data: [
                {
                  label: "统计全部原材料",
                  value: false,
                },
                {
                  label: "统计变化原材料",
                  value: true,
                },
              ],
            },
          },
          {
            type: "radio",
            label: "",
            filter: "isChangeCostPrice",
            noReset: true,
            model: false,
            change: (v) => {
              this.$refs.tablePage.getList();
            },
            hide: () => this.options?.tabColumnType == "products",
            option: {
              data: [
                {
                  label: "统计全部成品/半成品",
                  value: false,
                },
                {
                  label: "统计变化全部成品/半成品",
                  value: true,
                },
              ],
            },
          },
          {
            hide: () => this.options?.tabColumnType == "materials",
            type: 'title',
            tip: ''
          },
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          { type: "button", tip: "重置", click: "reset" }
        ],
        tabColumnType: "back",
        tabsColumns: [
          {
            getListApi: listMaterialCost,
            uuid: "d2f8b140-0f6f-9ca6-33e9-2c3a14bbc492",
            title: "原材料列表",
            type: "materials",
            buttons: [
              {
                click: "updateOneCostPrice",
                label: "更新成本",
                type: "primary",
              },
              {
                click: "refresh",
                right: true,
                label: "刷新",
                icon: "el-icon-refresh",
                type: "",
              },
            ],
            columns: [
              {
                prop: "goodsNo",
                label: "原料编码",
                minWidth: 155,
              },
              {
                prop: "goodsName",
                label: "原料名称",
                minWidth: 155,
              },
              {
                prop: "barcode",
                label: "条码",
                minWidth: 155,
              },
              {
                prop: "zjm",
                label: "助记码",
                minWidth: 100,
              },
              {
                prop: "goodsSpec",
                label: "规格",
                minWidth: 120,
              },
              {
                prop: "unitName",
                label: "基础单位",
                minWidth: 120,
              },
              {
                prop: "oneCostPrice",
                label: "配方成本价",
                minWidth: 120,
              },
              {
                prop: "purPrice",
                label: "标准进价",
                minWidth: 120,
              },
              {
                prop: "lastPurNotaxPrice",
                label: "最新进价",
                minWidth: 120,
              },
              {
                prop: "lastCostPrice",
                label: "最新成本",
                minWidth: 120,
              }
            ],
          },
          {
            getListApi: listGoodsCost,
            uuid: "da39b172-c9e7-0a14-746a-6befc94fba57",
            title: "成品/半成品待更新标价进价列表",
            type: "products",
            buttons: [
              {
                click: "updateGoodsPurPrice",
                label: "配方成本价更新到标准进价",
                type: "primary",
              },
              {
                click: "refresh",
                right: true,
                label: "刷新",
                icon: "el-icon-refresh",
                type: "",
              },
            ],
            columns: [
              {
                prop: "goodsNo",
                label: "成品/半成品编码",
                minWidth: 155,
              },
              {
                prop: "goodsName",
                label: "成品/半成品名称",
                minWidth: 155,
              },
              {
                prop: "barcode",
                label: "条码",
                minWidth: 155,
              },
              {
                prop: "zjm",
                label: "助记码",
                minWidth: 100,
              },
              {
                prop: "goodsSpec",
                label: "规格",
                minWidth: 120,
              },
              {
                prop: "unitName",
                label: "基础单位",
                minWidth: 120,
              },
              {
                prop: "oneCostPrice",
                label: "配方成本价",
                minWidth: 120,
              },
              {
                prop: "purPrice",
                label: "标准进价",
                minWidth: 120,
              }
            ],
          }
        ],
      },
    };
  },
  async activated() {
    const { data } = await produceListAPI()
    const tip = this.parametersObj[data.system_BUSINESS_PRODUCE_CONFIG_140]
    this.options.search[4].tip = tip ? `计算规则：${tip}` : ''
    if ((data.system_BUSINESS_PRODUCE_CONFIG_140 == 2 || data.system_BUSINESS_PRODUCE_CONFIG_140 == 3) && data.system_BUSINESS_PRODUCE_CONFIG_150 == 1) {
      this.options.tabsColumns[0].buttons = [{
        click: "refresh",
        right: true,
        label: "刷新",
        icon: "el-icon-refresh",
        type: "",
      }]
      this.options.tabsColumns[1].buttons = [{
        click: "refresh",
        right: true,
        label: "刷新",
        icon: "el-icon-refresh",
        type: "",
      }]
    } else {
      this.options.tabsColumns[0].buttons = [
        {
          click: "updateOneCostPrice",
          label: "更新成本",
          type: "primary",
        },
        {
          click: "refresh",
          right: true,
          label: "刷新",
          icon: "el-icon-refresh",
          type: "",
        },
      ]
      this.options.tabsColumns[1].buttons = [
        {
          click: "updateGoodsPurPrice",
          label: "配方成本价更新到标准进价",
          type: "primary",
        },
        {
          click: "refresh",
          right: true,
          label: "刷新",
          icon: "el-icon-refresh",
          type: "",
        },
      ]
    }
    this.parameters = data?.system_BUSINESS_PRODUCE_CONFIG_140
    this.tableKey = Date.now();
  },
  methods: {
    async handleEvent(type, row) {
      let arr = []
      switch (type) {
        case 'updateOneCostPrice':
          arr = this.options.check.map(item => ({ goodsId: item.goodsId, unitId: item.unitId, oneCostPrice: this.parameters == 1 ? item.purPrice : this.parameters == 2 ? item.lastPurNotaxPrice : this.parameters == 3 ? item.lastCostPrice : null }))
          await updateOneCostPrice(arr)
          this.$nextTick(() => {
            this.options.check = [];
          });
          await this.$refs.tablePage.getList();
          this.$message.success('更新成功！')
          break
        case 'updateGoodsPurPrice':
          arr = this.options.check.map(item => ({ goodsId: item.goodsId, purPrice: item.oneCostPrice }))
          await updateGoodsPurPrice(arr)
          this.$nextTick(() => {
            this.options.check = [];
          });
          await this.$refs.tablePage.getList();
          this.$message.success('更新成功！')
          break
        default:
          break;
      }
    },
  },
};
</script>
